import { getAxiosInstance } from "../http"

import { Appro } from "../models"

export class ApproService {

    static createAppro = async (data: any) => {
       
        const instance = getAxiosInstance()
        
        const response = await instance.post('distributors/appros', Appro.toJSON(data))

        const result = Appro.fromJSON(response.data)

        return result
    }

    static getApproHistory = async () => {

        const instance = getAxiosInstance()

        const response = await instance.get('distributors/appros')

        const result = response.data.map((appro: any) => Appro.fromJSON(appro) as Appro)

        return result
    }
}