import { DashCard, DistributorBalanceCard } from "../../components";

import { Button, Dimmer, DimmerDimmable, Loader, Segment, Image } from "semantic-ui-react";

import css from './distributor-dashboard.module.css';
import { useEffect, useState } from "react";
import { DashboardService } from "../../services";

export function DistributorDashboardPage() {


    const [balances, setBalances] = useState([])
    const [error, setError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {

        DashboardService.getDistributorBalances()
            .then((data) => {
                setBalances(data)
                setTimeout(() => setLoading(false), 1000)
            })
            .catch((error) => setError(true))


           
    }, [])

    return <div>
        <DistributorBalanceCard balance={1000000} />
        <div className={css.cardRow}>
            <DashCard title="Solde Cash" subTitle="30 jours" balance={95000} loading={loading} imageUrl="/images/porte-monnaie-2.png" />
            <DashCard title="Solde OM" subTitle="15 jours" balance={0} loading={loading} imageUrl="/images/orangemoney.png" />
            <DashCard title="Solde Wave" subTitle="05 jours" balance={0} loading={loading} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} />
            <DashCard title="Solde OM" subTitle="15 jours" balance={120000} loading={loading} imageUrl="/images/orangemoney.png" />
            {/* <DashCard title="Solde Wave" subTitle="05 jours" balance={78000} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} /> */}
            {/* <DashCard title="Solde OM" subTitle="15 jours" balance={120000} imageUrl="/images/orangemoney.png" />
                <DashCard title="Solde Wave" subTitle="05 jours" balance={78000} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} />
                <DashCard title="Solde OM" subTitle="15 jours" balance={120000} imageUrl="/images/orangemoney.png" />
                <DashCard title="Solde Wave" subTitle="05 jours" balance={78000} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} />
                <DashCard title="Solde OM" subTitle="15 jours" balance={120000} imageUrl="/images/orangemoney.png" />
                <DashCard title="Solde Wave" subTitle="05 jours" balance={78000} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} /> */}
        </div>

        <div className={css.statRow}>
            <div className={css.lineChart}>

            </div>

            <div className={css.statistics}>
                <h2 className="mb-30">Statistiques</h2>

                {/* <div className={css.statisticsCard}>
                        <h3 className="flex space-between">5 235 <span>80%</span></h3>
                        <span className="size-16">approvisionnements validés</span>
                    </div>
                    <div className={css.statisticsCard}>
                        <h3 className="flex space-between">150  <span>4 %</span></h3>
                        <span className="size-16">approvisionnements en cours</span>
                    </div>
                    <div className={css.statisticsCard}>
                        <h3 className="flex space-between">1 235 <span>16 %</span></h3>
                        <span className="size-16">approvisionnements en conflit</span>
                    </div> */}

                {/* <div className={css.statisticsCard}>
                        <h3>5 235</h3>
                        <span className="size-16">approvisionnements validés</span>
                    </div>
                    <div className={css.statisticsCard}>
                        <h3>150</h3>
                        <span className="size-16">approvisionnements en cours</span>
                    </div>
                    <div className={css.statisticsCard}>
                        <h3>1 235</h3>
                        <span className="size-16">approvisionnements en conflit</span>
                    </div> */}

                {/* <div className={css.statisticsCard}>
                    <h3>5 235</h3>
                    <span className="size-16">approvisionnements validés</span>
                    <h3>80%</h3>
                </div>
                <div className={css.statisticsCard}>
                    <h3>150</h3>
                    <span className="size-16">approvisionnements en cours</span>
                    <h3>4%</h3>
                </div>
                <div className={css.statisticsCard}>
                    <h3>1 235</h3>
                    <span className="size-16">approvisionnements en conflit</span>
                    <h3>16%</h3>
                </div> */}

                <DimmerDimmable as={Segment} dimmed={true}>
                    <Dimmer active={true} inverted>
                        <Loader><small>Chargement...</small></Loader>
                    </Dimmer>

                    <p><Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' /></p>
                    <p><Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' /></p>
                </DimmerDimmable>
            </div>
        </div>
    </div>
}