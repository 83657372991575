import { User } from '../models'
import { Header, Table, Image, Button, Icon } from 'semantic-ui-react'

import { NoData } from './no-data'

export function UserList({users}: {users: User[]}) {

    const maleAvatar = '/images/black-man.png'
    const femaleAvatar = '/images/black-woman.png'

    return users.length === 0 ?
    <NoData/> :
    <Table basic='very'>
    <Table.Header>
        <Table.Row>
            <Table.HeaderCell className="size-17">N°</Table.HeaderCell>
            <Table.HeaderCell className="size-17">Nom Complet</Table.HeaderCell>
            <Table.HeaderCell className="size-17">Phone</Table.HeaderCell>
            <Table.HeaderCell className="size-17">Salaire</Table.HeaderCell>
            <Table.HeaderCell className="size-17">Sexe</Table.HeaderCell>
            <Table.HeaderCell className="size-17">Statut</Table.HeaderCell>
            <Table.HeaderCell className="size-17">NIN</Table.HeaderCell>
            <Table.HeaderCell className="size-17">Role</Table.HeaderCell>
            <Table.HeaderCell className="size-17"></Table.HeaderCell>
        </Table.Row>
    </Table.Header>

    <Table.Body>

        {users.map(u =>
            <Table.Row key={u.id}>
                <Table.Cell>{u.id}</Table.Cell>
                <Table.Cell>
                    <Header as='h4' image>
                        <Image src={u.sex === 'M' ? maleAvatar : femaleAvatar} rounded size='mini' />
                        <Header.Content>
                            {u.firstname + ' ' + u.lastname }
                            <Header.Subheader>{u.company?.name}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.Cell>
                <Table.Cell>{u.phone}</Table.Cell>
                <Table.Cell>{u.salary}</Table.Cell>
                <Table.Cell>{u.sex === 'M' ? 'H' : 'F'}</Table.Cell>
                <Table.Cell>{u.maritalStatus}</Table.Cell>
                <Table.Cell> {u.nin}</Table.Cell>
                <Table.Cell>{u.role}</Table.Cell>
                <Table.Cell textAlign='right'>
                    <Button icon className='mr-10'><Icon name='cloud download' /></Button>
                    <Button icon className='mr-10'><Icon name='trash alternate'/></Button>
                    <Button icon className='mr-10'><Icon name='pencil alternate'/></Button>
                    <Button icon className='mr-10'><Icon name='eye'/></Button>
                </Table.Cell>
            </Table.Row>
        )}
    </Table.Body>
</Table>

}
