import { getAxiosInstance } from "../http";

export class DashboardService {

    static getDistributorBalances = async() => {

        const instance = getAxiosInstance()
        
        const response = await instance.get('distributors/balances')

        return response.data
    }
}
