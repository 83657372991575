export class Appro {

    public id?: number;
    public initiatorId?: number;
    public initiatorName?: string;
    public initiatorPermission?: string;
    public tillId?: number;
    public cashierName?: string;
    public amount?: number;
    public createdAt?: Date;
    public updatedAt?: Date;
    public validatedByCashierAt?: Date;
    public status?: string;
    public companyId?: number;
    public companyName?: string;
    public approType?: string;
    public comments?: string;

    constructor(args: {id?: number, initiatorId?: number, initiatorName?: string, initiatorPermission?: string, tillId?: number, cashierName?: string, amount?: number, createdAt?: Date, updatedAt?: Date, validatedByCashierAt?: Date, status?: string, companyId?: number, companyName?: string, approType?: string, comments?: string}) {
        this.id = args.id
        this.initiatorId = args.initiatorId
        this.initiatorName = args.initiatorName
        this.initiatorPermission = args.initiatorPermission
        this.tillId = args.tillId
        this.cashierName = args.cashierName
        this.amount = args.amount
        this.createdAt = args.createdAt
        this.updatedAt = args.updatedAt
        this.validatedByCashierAt = args.validatedByCashierAt
        this.status = args.status
        this.companyId = args.companyId
        this.companyName = args.companyName
        this.approType = args.approType
        this.comments = args.comments
    }


    static fromJSON(jsonData: any): Appro {
        const statusMapping: any = {
            pending: 'en attente',
            ongoing: 'en cours',
            rejected: 'rejeté'
        }
        return new Appro({
            id: jsonData.id,
            initiatorId: jsonData.initiator_id,
            initiatorName: jsonData.initiator_fullname,
            initiatorPermission: jsonData.initiator_permission,
            tillId: jsonData.till_id,
            cashierName: jsonData.till_user_fullname,
            amount: jsonData.amount,
            createdAt: new Date(jsonData.created_at),
            updatedAt: new Date(jsonData.updated_at),
            validatedByCashierAt: jsonData.validated_by_cashier_at ? new Date(jsonData.validated_by_cashier_at) : undefined,
            status: statusMapping[jsonData.status],
            companyId: jsonData.company_id,
            companyName: jsonData.company_name,
            approType: jsonData.appro_type,
            comments: jsonData.comments
        })
    }


    static toJSON(Appro: Appro): any {
        return JSON.stringify({
            till_id: Appro.tillId,
            amount: Appro.amount,
            appro_type: Appro.approType,
        })
    }
}