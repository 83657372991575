import { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import { Appro } from "../../models";
import { ApproService, HelperService } from "../../services";
import { DistributorApproForm, DistributorApproList, ErrorMessage, LoadingSpinner } from "../../components";
import { FeedbackMessage } from "../../constants";

export function DistributorProvisionningPage() {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userRole, setUserRole] = useState<string>('')
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [appros, setAppros] = useState<Appro[]>([])

    const addToList = (appro: Appro) => setAppros([appro, ...appros])

    const hideForm = () => setCreateModalVisible(false)


    useEffect(() => {

        setUserRole(HelperService.getRoleFromToken())

        ApproService.getApproHistory()
            .then(data => {
                setAppros(data)
                setLoading(false)
            })
            .catch(_ => setError(true))
            .finally(() => setLoading(false))
    }, [])

    return <div>

        {loading && <LoadingSpinner />}
        {error && <ErrorMessage errorText={FeedbackMessage.APPRO_FETCH_ERROR} />}

        {!loading && !error &&
            <>
                <div className='headerRow'>
                    <h2>Approvisionnement caisse</h2>
                    {
                        userRole === 'Dist Local' &&
                        !createModalVisible &&
                        <Button color='orange' className='size-16' onClick={() => setCreateModalVisible(!createModalVisible)}>
                            <Icon name="external alternate" /> Nouvel appro
                        </Button>
                    }
                </div>

                <div style={
                    {
                        height: createModalVisible ? '350px' : '0px',
                        overflow: 'hidden',
                        transition: 'height 0.5s ease-in-out',
                        marginBottom: createModalVisible ? '30px' : '0px'
                    }}>
                    <DistributorApproForm addToList={addToList} hideForm={hideForm} />
                </div>

                <DistributorApproList appros={appros} userRole={userRole}/>
            </>
        }
    </div>
}