import { Button, Icon, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table } from "semantic-ui-react";

import { CashReception } from "../models";
import { HelperService } from "../services";
import { NoData } from "./no-data";

export function DistributorCashReceptionList({ cashReceptions, userRole }: { cashReceptions: CashReception[], userRole: string }) {

    return <div>
        {
            cashReceptions.length === 0 ? <NoData /> :

                <Table basic='very'>
                    <TableHeader>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableHeaderCell className="size-17">Source</TableHeaderCell>
                            <TableHeaderCell className="size-17">Nom</TableHeaderCell>
                            {userRole === 'Admin' && <TableHeaderCell className="size-17">Compagnie</TableHeaderCell> }
                            <TableHeaderCell className="size-17">Montant</TableHeaderCell>
                            <TableHeaderCell className="size-17">Date</TableHeaderCell>
                            <TableHeaderCell className="size-17" style={{ textAlign: 'center' }}>Actions</TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>

                        {
                            cashReceptions.map((cashReception, index) =>
                                <TableRow style={{ fontSize: '15px' }} key={cashReception.id}>
                                    <TableCell>
                                        <span style={{ backgroundColor: '#f9fafb', padding: '10px', borderRadius: '5px', marginRight: '10px' }}>
                                            {/* <Icon name={cashReception.sourceType === 'partner' ? 'linkify' : 'building outline'} /></span> */}
                                            <Icon name='cart arrow down' color="orange" /></span>
                                        <strong className="size-17">{cashReception.sourceType === 'service' ? 'Service' : 'Partenaire'}</strong>
                                    </TableCell>
                                    <TableCell>
                                        <b className="size-17">{cashReception.sourceName}</b>
                                            <br/>
                                        <small>
                                            {cashReception.sourceType === 'service' && cashReception.bankOrContactFullname && cashReception.bankOrContactFullname + ''}
                                        </small>
                                    </TableCell>
                                    { userRole === 'Admin' && <TableCell>{cashReception.companyName}</TableCell> }
                                    <TableCell>{HelperService.formatAmount(cashReception.amount!)}</TableCell>
                                    <TableCell>{HelperService.formatDateTime(cashReception.createdAt!)}</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <Button  icon className='mr-10'><Icon name='trash alternate' /></Button>
                                        <Button color='orange' icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                        <Button color='black' icon><Icon name='eye' /></Button>
                                    </TableCell>
                                </TableRow>)
                        }
                    </TableBody>
                </Table>
        }
    </div>
}