import { Button, Icon } from "semantic-ui-react"
import { HelperService } from "../services"

export function DistributorBalanceCard(balance: { balance: number }) {
    
    return <div style={
        {
            border: '1px solid #eee',
            padding: '12px',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    }>
        <div className="flex-center">   
            <img className="mr-15" src="/images/moneybag-4.png" alt=""  width={40}/>
            <div>
                <span className="">Solde courant</span>
                <h3 style={{marginTop: '5px'}}>
                    <span>{HelperService.formatAmount(balance.balance)}</span> Fcfa
                </h3>
            </div>
        </div>

        <div>
            <Button size="large" color="orange"><Icon name="cart arrow down"/> Réception</Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <Button size="large" color="black"><Icon name="long arrow alternate up"/> Approvisionnement</Button>
        </div>
    </div>

}