export class CashReception {
    public id?: number;
    public bankOrContactFullname?: string;
    public sourceType?: string;
    public sourceName?: string;
    public sourceId?: number;
    public amount?: number;
    public moneyType?: string;
    public distributorId?: number;
    public companyId?: number;
    public companyName?: string;
    public comments?: string;
    public createdAt?: Date;
    public updatedAt?: Date;

    constructor(args: {id?: number, sourceType?: string, sourceName?: string,  sourceId?: number, amount?: number, moneyType?:string, distributorId?: number, 
                        companyId?: number, companyName?: string, comments?: string, createdAt?: Date, updatedAt?: Date, bankOrContactFullname?: string}) {
        this.id = args.id
        this.sourceType = args.sourceType
        this.sourceId = args.sourceId
        this.sourceName = args.sourceName
        this.amount = args.amount
        this.moneyType = args.moneyType
        this.distributorId = args.distributorId
        this.companyId = args.companyId
        this.companyName = args.companyName
        this.comments = args.comments
        this.createdAt = args.createdAt
        this.updatedAt = args.updatedAt
        this.bankOrContactFullname = args.bankOrContactFullname
    }

    static fromJSON(json: any): CashReception {

        return new CashReception({
                id: json.id,
                sourceType: json.source_type,
                sourceId: json.source_id,
                sourceName: json.source_name,
                amount: json.amount,
                distributorId: json.distributor_id,
                companyId: json.company_id,
                companyName: json.company_name,
                comments: json.comments,
                createdAt: new Date(json.created_at),
                updatedAt: new Date(json.updated_at),
                bankOrContactFullname: json.bank_or_contact_fullname
        })
    }

    static toJSON(cashReception: CashReception): any {

        return JSON.stringify({
            source_type: cashReception.sourceType,
            ...(cashReception.sourceType === 'service' && {service_source_id: cashReception.sourceId}),
            ...(cashReception.sourceType === 'partner' && {partner_source_id: cashReception.sourceId}),
            amount: cashReception.amount,
            money_type: cashReception.moneyType,
            comments: cashReception.comments
        })
    }
}

