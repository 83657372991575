import { Button, Icon, Table } from 'semantic-ui-react'

import { Partner } from "../models"

import { NoData } from "./no-data";

export function PartnerList({ partners, userRole }: { partners: Partner[], userRole: string }) {
    return <div>
        {
            partners.length === 0 ?  <NoData/> :

            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>N°</Table.HeaderCell>
                        {userRole === 'Admin' && <Table.HeaderCell className="size-17">Company</Table.HeaderCell>}
                        <Table.HeaderCell className="size-17">Nom</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Contact</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Téléphone</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Adresse</Table.HeaderCell>
                        <Table.HeaderCell className="size-17" style={{textAlign: 'center'}}>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                    partners.map(partner => 
                        <Table.Row key={partner.id} className='size-15'>
                            <Table.Cell>{partner.id}</Table.Cell>
                            {userRole === 'Admin' && <Table.Cell>{partner.companyName}</Table.Cell>}
                            <Table.Cell>{partner.name}</Table.Cell>
                            <Table.Cell>{partner.contactFullname}</Table.Cell>
                            <Table.Cell>{partner.phone}</Table.Cell>
                            <Table.Cell>{partner.address}</Table.Cell>
                            <Table.Cell style={{textAlign: 'right'}}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </Table.Cell>
                        </Table.Row>)
                    }
                </Table.Body>
            </Table>
        }
    </div>

}