import { Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react"
import { Appro } from "../models"
import { NoData } from "./no-data"
import { HelperService } from "../services"

export function DistributorApproList({ appros, userRole }: { appros: Appro[], userRole: string }) {
    return <div>
        {
            appros.length === 0 ? <NoData /> :

                <Table basic='very'>
                    <TableHeader>
                        <TableRow style={{ fontSize: '15px' }}>
                            <Table.HeaderCell>N°</Table.HeaderCell>
                            <TableHeaderCell className="size-17">Compagnie</TableHeaderCell>
                            <TableHeaderCell className="size-17">Auteur</TableHeaderCell>
                            <TableHeaderCell className="size-17">Montant</TableHeaderCell>
                            <TableHeaderCell className="size-17">Type</TableHeaderCell>
                            <TableHeaderCell className="size-17">Statut</TableHeaderCell>
                            <TableHeaderCell className="size-17">Caisse</TableHeaderCell>
                            <TableHeaderCell className="size-17">Création</TableHeaderCell>
                            <TableHeaderCell className="size-17">Validation</TableHeaderCell>
                            <TableHeaderCell className="size-17" style={{ textAlign: 'center' }}>Actions</TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>

                        {
                            appros.map((appro, index) =>
                                <TableRow style={{ fontSize: '15px' }} key={appro.id}>
                                    <TableCell>{appro.id}</TableCell>
                                    <TableCell>{appro.companyName}</TableCell>
                                    <TableCell>{appro.initiatorName}</TableCell>
                                    <TableCell>{HelperService.formatAmount(appro.amount!)}</TableCell>
                                    <TableCell>{appro.approType}</TableCell>
                                    <TableCell>{appro.status}</TableCell>
                                    <TableCell>{appro.cashierName}</TableCell>
                                    <TableCell>{HelperService.formatDateTime(appro.createdAt!)}</TableCell>
                                    <TableCell>{appro.validatedByCashierAt ? HelperService.formatDateTime(appro.validatedByCashierAt!) : ''}</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                        <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                        <Button icon><Icon name='eye' /></Button>
                                    </TableCell>
                                </TableRow>)
                        }

                    </TableBody>
                </Table>
        }
    </div>
}
